import React from "react";
import "./Request.css"; // Import the corresponding CSS file
import bgImage from "./images/bg_5.jpg"; // Example: Import your background image
import courseImage1 from "./images/course-1.jpg";
import courseImage2 from "./images/image_2.jpg";
import courseImage3 from "./images/image_3.jpg";
import courseImage4 from "./images/image_4.jpg";

const Request = () => {
  return (
    <section
      className="ftco-section ftco-consult ftco-no-pt ftco-no-pb"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      data-stellar-background-ratio="0.5"
    >
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-md-6 py-5 px-md-5 bg-primary">
            <div className="heading-section heading-section-white ftco-animate mb-5">
              <span className="subheading">Request A Quote</span>
              <h2 className="mb-4">Request A Quote</h2>
              <p>
                "Education is not the filling of a pail, but the lighting of a
                fire in a child's heart, where curiosity becomes the compass and
                knowledge the journey."
              </p>
            </div>
            <form action="#" className="appointment-form ftco-animate">
              <div className="d-md-flex">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                  />
                </div>
                <div className="form-group ml-md-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="d-md-flex">
                <div className="form-group">
                  <div className="form-field">
                    <div className="select-wrap">
                      <div className="icon">
                        <span className="ion-ios-arrow-down"></span>
                      </div>
                      <select name="" id="" className="form-control">
                        <option value="">Select Your Course</option>
                        <option value="">Art Lesson</option>
                        <option value="">Language Lesson</option>
                        <option value="">Music Lesson</option>
                        <option value="">Sports</option>
                        <option value="">Other Services</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group ml-md-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                  />
                </div>
              </div>
              <div className="d-md-flex">
                <div className="form-group">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="2"
                    className="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="form-group ml-md-4">
                  <input
                    type="submit"
                    value="Request A Quote"
                    className="btn btn-secondary py-3 px-4"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const Gallery = () => {
  return (
    <section className="ftco-gallery">
      <div className="container-wrap">
        <div className="row no-gutters">
          <div className="col-md-3 ftco-animate">
            <a
              href={courseImage1}
              className="gallery image-popup img d-flex align-items-center"
              style={{ backgroundImage: `url(${courseImage1})` }}
            >
              <div className="icon mb-4 d-flex align-items-center justify-content-center">
                <span className="icon-instagram"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href={courseImage2}
              className="gallery image-popup img d-flex align-items-center"
              style={{ backgroundImage: `url(${courseImage2})` }}
            >
              <div className="icon mb-4 d-flex align-items-center justify-content-center">
                <span className="icon-instagram"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href={courseImage3}
              className="gallery image-popup img d-flex align-items-center"
              style={{ backgroundImage: `url(${courseImage3})` }}
            >
              <div className="icon mb-4 d-flex align-items-center justify-content-center">
                <span className="icon-instagram"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href={courseImage4}
              className="gallery image-popup img d-flex align-items-center"
              style={{ backgroundImage: `url(${courseImage4})` }}
            >
              <div className="icon mb-4 d-flex align-items-center justify-content-center">
                <span className="icon-instagram"></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const YourComponent = () => {
  return (
    <>
      <Request />
      <Gallery />
    </>
  );
};

export default Request;
