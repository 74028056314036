//App.js
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "./App.css";
import Home from "./Home.js";
import About from "./About.js";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/About" element={<About/>}/> 
     
     </Routes> 
     </BrowserRouter>
  
  );
};

export default App;