import React from "react";
import './Footer.css'; // Import your custom CSS if needed

const Footer = () => {
  return (
    <footer className="ftco-footer ftco-bg-dark ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6 col-lg-3">
            <div className="ftco-footer-widget mb-5">
              <h2 className="ftco-heading-2">Address</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <span className="icon icon-map-marker"></span>
                    <span className="text">
                      Uttam Nagar , West Delhi , INDIA .
                    </span>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon icon-phone"></span>
                      <span className="text">+91-9079177216</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon icon-envelope"></span>
                      <span className="text">indiashometuition@gmail.com</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="ftco-footer-widget mb-5">
              <h2 className="ftco-heading-2">Recent Blog</h2>
              <div className="block-21 mb-4 d-flex">
                <a className="blog-img mr-4" style={{ backgroundImage: "url(images/image_1.jpg)" }}></a>
                <div className="text">
                  <h3 className="heading">
                    <a href="#">Even the all-powerful Pointing has no control about</a>
                  </h3>
                  <div className="meta">
                    <div>
                      <a href="#">
                        <span className="icon-calendar"></span> 
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <span className="icon-person"></span> Admin
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <span className="icon-chat"></span> 
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-21 mb-5 d-flex">
                <a className="blog-img mr-4" style={{ backgroundImage: "url(images/image_2.jpg)" }}></a>
                <div className="text">
                  <h3 className="heading">
                    <a href="#">Even the all-powerful Pointing has no control about</a>
                  </h3>
                  <div className="meta">
                    <div>
                      <a href="#">
                        <span className="icon-calendar"></span> 
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <span className="icon-person"></span> Admin
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <span className="icon-chat"></span> 
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="ftco-footer-widget mb-5 ml-md-4">
              <h2 className="ftco-heading-2">Links</h2>
              <ul className="list-unstyled">
                <li>
                  <a href="#">
                    <span className="ion-ios-arrow-round-forward mr-2"></span>Home
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="ion-ios-arrow-round-forward mr-2"></span>About
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="ion-ios-arrow-round-forward mr-2"></span>Services
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="ion-ios-arrow-round-forward mr-2"></span>Departments
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="ion-ios-arrow-round-forward mr-2"></span>Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="ftco-footer-widget mb-5">
              <h2 className="ftco-heading-2">Subscribe Us!</h2>
              <form action="#" className="subscribe-form">
                <div className="form-group">
                  <input type="text" className="form-control mb-2 text-center" placeholder="Enter email address" />
                  <input type="submit" value="Subscribe" className="form-control submit px-3" />
                </div>
              </form>
            </div>
            <div className="ftco-footer-widget mb-5">
              <h2 className="ftco-heading-2 mb-0">Connect With Us</h2>
              <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
                <li className="ftco-animate">
                  <a href="#">
                    <span className="icon-twitter"></span>
                  </a>
                </li>
                <li className="ftco-animate">
                  <a href="#">
                    <span className="icon-facebook"></span>
                  </a>
                </li>
                <li className="ftco-animate">
                  <a href="#">
                    <span className="icon-instagram"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p>
              Copyright &copy;{new Date().getFullYear()} All rights reserved by @IndiasHomeTuition || 
             
              
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
