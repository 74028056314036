import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhoneAlt, faCommentDots, faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Nave.css";


const Nave = () => {
  return (
  
    <div className="navbar-wrapper w-100">
      <div className="bg-primary d-none d-lg-block py-2 w-100" style={{ borderRadius: "0 40px" }}>
        <div className="d-flex justify-content-between">
          <div className="top-info ps-2">
            <small className="me-3">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 text-secondary" />
              <a href="#" className="text-white">Jaipur , Rajasthan .</a>
            </small>
            <small className="me-3">
              <FontAwesomeIcon icon={faEnvelope} className="me-2 text-secondary" />
              <a href="#" className="text-white">Indiashometuition@gmail.com</a>
            </small>
          </div>
          <div className="top-link pe-2">
            <a href="#" className="btn btn-light btn-sm-square rounded-circle">
              <FontAwesomeIcon icon={faFacebookF} className="text-secondary" />
            </a>
            <a href="#" className="btn btn-light btn-sm-square rounded-circle">
              <FontAwesomeIcon icon={faTwitter} className="text-secondary" />
            </a>
            <a href="#" className="btn btn-light btn-sm-square rounded-circle">
              <FontAwesomeIcon icon={faInstagram} className="text-secondary" />
            </a>
            <a href="#" className="btn btn-light btn-sm-square rounded-circle me-0">
              <FontAwesomeIcon icon={faLinkedinIn} className="text-secondary" />
            </a>
          </div>
        </div>
      </div>

      {/* Full-width Navbar */}
      <div className="navbar-container w-100 px-0">
        <nav className="navbar navbar-light navbar-expand-xl py-3 w-100">
          <a href="index.html" className="navbar-brand">
            <h1 className="text-primary display-6">Indias<span className="text-secondary">HomeTuition</span></h1>
          </a>
          <button className="navbar-toggler py-2 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <FontAwesomeIcon icon={faBars} className="text-primary" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto">
              <a href="index.html" className="nav-item nav-link">Home</a>
              <a href="about.html" className="nav-item nav-link">About</a>
              <a href="service.html" className="nav-item nav-link">Services</a>
              <a href="program.html" className="nav-item nav-link">Programs</a>
              <a href="event.html" className="nav-item nav-link">Events</a>
              <div className="nav-item dropdown">
                <a href="#" className="nav-link dropdown-toggle active" data-bs-toggle="dropdown">Pages</a>
                <div className="dropdown-menu m-0 bg-secondary rounded-0">
                  <a href="blog.html" className="dropdown-item">Our Blog</a>
                  <a href="team.html" className="dropdown-item">Our Team</a>
                  <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                  <a href="404.html" className="dropdown-item active">404 Page</a>
                </div>
              </div>
              <a href="contact.html" className="nav-item nav-link">Contact</a>
            </div>
            <div className="d-flex me-4">
              <div id="phone-tada" className="d-flex align-items-center justify-content-center">
                <a href="#" className="position-relative wow tada" data-wow-delay=".9s">
                  <FontAwesomeIcon icon={faPhoneAlt} className="text-primary fa-2x me-4" />
                  <div className="position-absolute" style={{ top: "-7px", left: "20px" }}>
                    <span><FontAwesomeIcon icon={faCommentDots} className="text-secondary" /></span>
                  </div>
                </a>
              </div>
              <div className="d-flex flex-column pe-3 border-end border-primary">
                <span className="text-primary">Have any questions?</span>
                <a href="#"><span className="text-secondary">Free: + 91-9079177216</span></a>
              </div>
            </div>
            <button className="btn-search btn btn-primary btn-md-square rounded-circle" data-bs-toggle="modal" data-bs-target="#searchModal">
              <FontAwesomeIcon icon={faSearch} className="text-white" />
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Nave;
