import React, { useState } from "react";

// import "./Nave.css";
// import "./About.css";
// import CardItems from './CardItems';

import Nave from "./Nave.js";
import About from "./About.js";
import Header from "./Header.js";
import Request from "./Request.js";
import Footer from "./Footer.js";
// import ButtonPage from './ButtonPage';

const Home = () => {
  return (
    <div>
      <Nave/>
     <div><Header/></div> 
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
     <div><About/></div> 
     <br/>
     <br/>
     <br/>
     <br/>
     <div><Request/></div>
     <br/>
     <br/>
     <Footer/>
    </div>
  );
};


export default Home;
