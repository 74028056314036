import React from "react";
import "./Header.css"; // Assuming you have a CSS file for styling
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is installed and imported
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import Bootstrap JavaScript
// import sliderbg from "./images/slider-bg.jpg"; // Ensure this path is correct

const Header = () => {
  return (
    <section className="slider_section position-relative">
      <div className="container">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {/* Carousel Items */}
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="detail-box">
                      <h1>
                        A Perfect Learning Center <br />
                        <span>For Your Kids</span>
                      </h1>
                      <p>
                        "Greatness begins with the right guidance – discover a
                        tutor who will awaken your child's true potential."
                      </p>
                      <div className="btn-box">
                        <a href="/" className="btn-1">
                          Read More
                        </a>
                        <a href="/" className="btn-2">
                          Contact us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
